import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { ListsService } from 'src/app/objects/lists';
import { AlertService } from 'src/app/services/alert.service';
import { FrameService } from 'src/app/services/frame.service';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-add-frame',
  templateUrl: './add-frame.component.html',
  styleUrls: ['./add-frame.component.css']
})
export class AddFrameComponent implements OnInit {

  @Input() frame: any;
  @Input() icons: any;
  frameValue: any = null;
  public events: any;
  public eventTypes: any;
  public userRoles: any;
  public sourceTypes: any;
  public fontFamilies: any;

  frameForm: FormGroup;
  errorMessage: string = null;
  showSpinnerForm: boolean = true;

  public stationId: Number = null;

  constructor(
    public frameService: FrameService, 
    public lists: ListsService, 
    private settingsService: SettingsService,
    private fb: FormBuilder, 
    private router: Router,
    private alertService: AlertService,
    private route: ActivatedRoute) {
      this.route.queryParams.subscribe(params => {
        this.stationId = params['stationId'];
      });
    }

  ngOnChanges(): void {
    if(this.frame){
      this.frame.subscribe((x) => {
        this.frameValue = x;
      });
    }
  }

  ngOnInit() {
    this.lists.getUserRolesList().subscribe((items: any) => {
      this.userRoles = items;
    });
    this.lists.getEventTypesList().subscribe((items: any) => {
      this.eventTypes = items;
    });
    this.lists.getSupportedFontFamilies().subscribe((items: any) => {
      this.fontFamilies = items;
    });
    this.settingsService.getSettingsByType('events_list').subscribe((res: any) => {
      if(res){
        this.events = JSON.parse(res['metadata']);
      }
    });
    this.lists.getSourceTypes().subscribe((items: any) => {
      this.sourceTypes = items;
    });
    this.populateForm();
  }

  handleCardStyleChange(event:any, source: string){
    let newValue = this.frameValue;
    if(source === 'style'){
      newValue.style = event.value;
    }else if(source === 'langType'){
      newValue.langType = event.value;
    }else if(source === 'frameNavType'){
      newValue.frameNavType = event.value;
    }
    this.frame.next(newValue);
  }

  populateForm(){
    this.showSpinnerForm = true;
    this.createForm();
    this.showSpinnerForm = false;
  }

  createForm() {
    let formGroup: any = {};
    for (let [key, value] of Object.entries(this.frameValue)) {
      formGroup[key] = new FormControl(this.frameValue[key]);
    }
    this.frameForm = this.fb.group(formGroup);
  }

  // convenience getter for easy access to form fields
  get f() { return this.frameForm.controls; }

  saveForm(form: any){
    let formValue = form.value;
    let metadata:any = {};
    metadata.langType = formValue.langType;
    metadata.style = formValue.style;
    metadata.pageAnimation = formValue.pageAnimation;
    metadata.textOnly = formValue.textOnly;
    metadata.buttonsType = formValue.buttonsType;
    metadata.exitMode = formValue.exitMode;
    metadata.exitText = formValue.exitText;
    metadata.closeButtonIcon = formValue.closeButtonIcon;
    metadata.closeButtonSize = formValue.closeButtonSize;
    metadata.timeLimit = formValue.timeLimit;
    metadata.buttonTopLeftStyle = formValue.buttonTopLeftStyle;
    metadata.buttonTopRightStyle = formValue.buttonTopRightStyle;
    metadata.buttonBottomLeftStyle = formValue.buttonBottomLeftStyle;
    metadata.buttonBottomRightStyle = formValue.buttonBottomRightStyle;
    metadata.buttonsOpacity = formValue.buttonsOpacity;
    metadata.buttonTimeHideDelay = formValue.buttonTimeHideDelay;
    metadata.componentsBgColor = formValue.componentsBgColor;
    metadata.componentsFontColor = formValue.componentsFontColor;
    metadata.componentsIconColor = formValue.componentsIconColor;
    metadata.componentsButtonColor = formValue.componentsButtonColor;
    metadata.hideComponentsShadow = formValue.hideComponentsShadow;
    metadata.hideHeaderCollapseComponents = formValue.hideHeaderCollapseComponents;
    metadata.hideIconOnHeaderCollapseComponents = formValue.hideIconOnHeaderCollapseComponents;
    
    let customEvents: any = {};
    this.eventTypes.forEach(element => {
      if(formValue[element.id] && formValue[element.id].length === 0){
        customEvents[element.id] = null;
      }else{
        customEvents[element.id] = formValue[element.id];
      }
    });
    metadata.customEvents = customEvents;

    metadata.customCss = JSON.parse(formValue.customCss);
    metadata.customCss = {};
    metadata.customCss['background-color'] = formValue.frameBGColor;
    metadata.customCss.componentHeader = {};
    metadata.customCss.componentHeader['font-size'] = formValue.componentsHFontSize;
    metadata.customCss.componentHeader['font-family'] = formValue.componentsHFontFamily;
    metadata.customCss.componentHeader['text-align'] = formValue.componentsHFontAlignment;
    metadata.customCss.componentHeader['line-height'] = formValue.componentsHLineHeight;
    metadata.customCss.componentHeader['letter-spacing'] = formValue.componentsHLetterSpacing;
    metadata.customCss.componentBody = {};
    metadata.customCss.componentBody['font-size'] = formValue.componentsBFontSize;
    metadata.customCss.componentBody['font-family'] = formValue.componentsBFontFamily;
    metadata.customCss.componentBody['text-align'] = formValue.componentsBFontAlignment;
    metadata.customCss.componentBody['line-height'] = formValue.componentsBLineHeight;
    metadata.customCss.componentBody['letter-spacing'] = formValue.componentsBLetterSpacing;
    
    metadata.delayedClosedCardTime = formValue.delayedClosedCardTime;
    metadata.delayCloseCardByEvent = formValue.delayCloseCardByEvent;
    metadata.frameNavType = formValue.frameNavType;
    metadata.allowEdit = formValue.allowEdit;
    metadata.simpleRegistration = formValue.simpleRegistration;
    metadata.sessionId = formValue.sessionId;
    metadata.emailLabel = formValue.emailLabel;
    metadata.usernameLabel = formValue.usernameLabel;
    metadata.passwordLabel = formValue.passwordLabel;
    metadata.loginLabel = formValue.loginLabel;
    metadata.registerLabel = formValue.registerLabel;
    metadata.submitLabel = formValue.submitLabel;
    if(formValue.allowEdit){
      metadata['appendComponent'] = {'type': 'action', 'userRole': formValue.userRole};
    }

    let newFrame:any = {};
    newFrame.description = formValue.description;
    newFrame.lang = formValue.lang;
    newFrame.metadata = JSON.stringify(metadata);
    //console.log(newFrame.metadata);return;
    this.showSpinnerForm = true;
    this.frameService.addFrame(newFrame).pipe(first()).subscribe({
      next: (response:any) => {
          this.alertService.success('New Frame is Added Successfully!', { keepAfterRouteChange: true });
          this.router.navigate(['/browse'],{ /*queryParams: {id: response.id, action: 'edit', stationId: this.stationId}*/});
          this.showSpinnerForm = false;
      },
      error: error => {
          this.alertService.error(error, { keepAfterRouteChange: true });
          this.showSpinnerForm = false;
      }
    });
  }

}
